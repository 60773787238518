<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_notification"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_notification" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 14pt; width: 100%;" class="fonte mt-1">
          ASSUNTO: {{ get_notification.title }}
        </span>
        <div class="expande-horizontal"></div>
      </div>

      <div class="expande-horizontal wrap py-6 pt-0 pb-0">
        <v-flex class="pa-3" xs12>
          <div class="expande-horizontal column">
            <span class="pa-3">
              {{ get_notification.message }}
            </span>
          </div>
        </v-flex>
      </div>

      <div class="expande-horizontal" style="background: #333;">
        <span class="font-weight-light pa-3 white--text">
          Recebida em:
          {{ $moment(get_notification.created_at).format("llll") }}
        </span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_notification",
      "getProfessores",
      "get_modal_view_notification",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_notification",
      "atualizar_notification",
      "listarProfessores",
      "fecha_modal_view_notification"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_notification._id
          ? this.atualizar_notification()
          : this.criar_notification();
      }
    }
  },
  created() {
    this.listarProfessores();
  }
};
</script>
